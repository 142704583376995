import { render, staticRenderFns } from "./AddFormTemplates.vue?vue&type=template&id=175b1866&scoped=true"
import script from "./AddFormTemplates.vue?vue&type=script&lang=js"
export * from "./AddFormTemplates.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "175b1866",
  null
  
)

export default component.exports